import { Button, Layout, Menu } from 'antd';
import 'antd/dist/antd.css';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { Images } from '../../assets/images/Index';
import { PermissionEnum } from '../../enums/Permission.enum';
import { ProductShopStatusEnum, ProductStatusEnum } from '../../enums/ProductStatus.enum';
import { useAuthStore } from '../../store';
import { AcademyIcon } from './icons';
import { CategoryIcon } from './icons/CargoryIcon';
import { DashboardIcon } from './icons/DashboardIcon';
import { LocalSellers } from './icons/LocalSellers';
import { OrdersIcon } from './icons/OrdersIcon';
import { ProductsIcon } from './icons/ProductsIcon';
import { ServiceIcon } from './icons/ServiceIcon';
import { SettingsIcon } from './icons/SettingsIcon';

export const Sidebar = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { user } = useAuthStore();
  const getStatus = new URLSearchParams(location.search);
  const searchParam = getStatus.get('search_text');
  const searchTypeParam = getStatus.get('search_type');
  const languageParam = getStatus.get('language');
  const selectedSellerParam = getStatus.get('seller');
  const selectedCategoryParam = getStatus.getAll('category');
  const selectedUserParam = getStatus.get('user');
  const showTranslatedParam = getStatus.get('show_translated');
  const showCategoriesParam = getStatus.get('show_categories');
  const showBrandsParam = getStatus.get('show_brands');
  const showChangedParam = getStatus.get('show_updated');
  const showPriceParam = getStatus.get('show_price');
  const showStockParam = getStatus.get('show_stock');
  const showVendorListParam = getStatus.get('show_is_in_vendor_list');
  const showImagesParam = getStatus.get('show_images');
  const shopId = getStatus.get('shopId');
  const isFromLocalPage = location.pathname === '/local/products';

  const [collapse, setCollapse] = useState(false);
  const toggleCollapse = () => setCollapse(!collapse);
  const [searchParams] = useSearchParams();

  const checkAccess = (permission: string) => user?.permissions?.includes(permission);

  const toLink = (status?: any) => ({
    pathname: '/products',
    search: isFromLocalPage
      ? `shopId=${shopId}${status != null ? `&status=${status}` : ''}`
      : `shopId=${shopId}${status != null ? `&status=${status}` : ''}${
          languageParam != null ? `&language=${languageParam}` : ''
        }${searchParam != null ? `&search_text=${searchParam}` : ''}${
          searchTypeParam != null ? `&search_type=${searchTypeParam}` : ''
        }${selectedSellerParam != null ? `&seller=${selectedSellerParam}` : ''}${
          selectedCategoryParam != null
            ? selectedCategoryParam.map(cat => `&category=${cat}`).join('')
            : ''
        }${selectedUserParam != null ? `&user=${selectedUserParam}` : ''}${
          showTranslatedParam != null ? `&show_translated=${showTranslatedParam}` : ''
        }${showImagesParam != null ? `&show_images=${showImagesParam}` : ''}${
          showCategoriesParam != null ? `&show_categories=${showCategoriesParam}` : ''
        }${showBrandsParam != null ? `&show_brands=${showBrandsParam}` : ''}${
          showChangedParam != null ? `&show_updated=${showChangedParam}` : ''
        }${showPriceParam != null ? `&show_price=${showPriceParam}` : ''}${
          showStockParam != null ? `&show_stock=${showStockParam}` : ''
        }${showVendorListParam != null ? `&show_is_in_vendor_list=${showVendorListParam}` : ''}`,
  });

  useEffect(() => {
    if (location.pathname == '/settings/import') {
      setOpenKeys(prev => [...prev, 'settings']);
    }
    if (searchParams.get('action') && searchParams.get('action') === 'create-localproduct') {
      setCollapse(true);
    } else {
      setCollapse(false);
    }
  }, [searchParams, location.pathname]);

  const [openKeys, setOpenKeys] = useState(['2', location.pathname.split('/')[1]]);

  const handleOpenChange = (keys: any) => setOpenKeys(keys);

  return (
    <Layout.Sider
      width="270"
      trigger={null}
      collapsible
      collapsed={collapse}
      className="sider-menu"
      style={{
        height: '100vh',
        position: 'sticky',
        left: 0,
        top: 0,
        bottom: 0,
        zIndex: 10,
      }}
    >
      <div className={collapse ? `icon` : `logo`}>
        <img
          className={collapse ? `icon` : `logo`}
          src={
            collapse
              ? Images.Icon
              : 'https://foleja.fra1.cdn.digitaloceanspaces.com/static/logo-login-light.png'
          }
        />
        <Button
          className="trigger"
          type="primary"
          onClick={toggleCollapse}
          style={{ marginBottom: 16 }}
        >
          <img src={collapse ? Images.arrowRight : Images.arrowLeft} />
        </Button>
      </div>
      <Menu
        openKeys={openKeys}
        onOpenChange={handleOpenChange}
        defaultSelectedKeys={['2']}
        selectedKeys={[`${location.pathname}?shopId=${shopId}`]}
        mode="inline"
        theme="light"
        inlineCollapsed={collapse}
      >
        <Menu.Item key="/" icon={<DashboardIcon />}>
          <Link to={{ pathname: '/', search: `shopId=${shopId}` }}>
            {user?.roles.includes('Local Seller') ? 'Paneli' : 'Dashboard'}
          </Link>
        </Menu.Item>

        {checkAccess(PermissionEnum.READ_PRODUCT) && (
          <Menu.SubMenu key="2" icon={<ProductsIcon />} title={t('sidebar_products')}>
            {checkAccess(PermissionEnum.READ_PRODUCT_DATA) && (
              <>
                <Menu.Item key="/products/review">
                  <Link
                    to={{
                      pathname: '/products/review',
                      search: isFromLocalPage
                        ? `shopId=${shopId}`
                        : `shopId=${shopId}${
                            languageParam != null ? `&language=${languageParam}` : ''
                          }${searchParam != null ? `&search_text=${searchParam}` : ''}${
                            searchTypeParam != null ? `&search_type=${searchTypeParam}` : ''
                          }${selectedSellerParam != null ? `&seller=${selectedSellerParam}` : ''}${
                            selectedCategoryParam != null
                              ? selectedCategoryParam.map(cat => `&category=${cat}`).join('')
                              : ''
                          }${selectedUserParam != null ? `&user=${selectedUserParam}` : ''}${
                            showTranslatedParam != null
                              ? `&show_translated=${showTranslatedParam}`
                              : ''
                          }${showImagesParam != null ? `&show_images=${showImagesParam}` : ''}${
                            showCategoriesParam != null
                              ? `&show_categories=${showCategoriesParam}`
                              : ''
                          }${showBrandsParam != null ? `&show_brands=${showBrandsParam}` : ''}${
                            showChangedParam != null ? `&show_updated=${showChangedParam}` : ''
                          }${showPriceParam != null ? `&show_price=${showPriceParam}` : ''}${
                            showStockParam != null ? `&show_stock=${showStockParam}` : ''
                          }${
                            showVendorListParam != null
                              ? `&show_is_in_vendor_list=${showVendorListParam}`
                              : ''
                          }`,
                    }}
                    replace
                  >
                    {t('sidebar_products_review_and_fix')}
                  </Link>
                </Menu.Item>
                <Menu.Item key="/products">
                  <Link to={toLink()} replace>
                    {t('sidebar_products_all')}
                  </Link>
                </Menu.Item>
              </>
            )}
            <Menu.Item key={`/products?shopId=${shopId}&status=${ProductStatusEnum.NEW}`}>
              <Link to={toLink(ProductStatusEnum.NEW)} replace>
                {t('sidebar_products_new')}
              </Link>
            </Menu.Item>

            <Menu.Item key={`/products?shopId=${shopId}&status=${ProductStatusEnum.PENDING}`}>
              <Link to={toLink(ProductStatusEnum.PENDING)} replace>
                {t('sidebar_products_pending')}
              </Link>
            </Menu.Item>
            <Menu.Item key={`/products?shopId=${shopId}&status=${ProductStatusEnum.APPROVED}`}>
              <Link to={toLink(ProductStatusEnum.APPROVED)} replace>
                {t('sidebar_approved')}
              </Link>
            </Menu.Item>

            <Menu.Item key={`/products?shopId=${shopId}&status=${ProductShopStatusEnum.IN_SHOP}`}>
              <Link to={toLink(ProductShopStatusEnum.IN_SHOP)} replace>
                {t('sidebar_products_shop')}
              </Link>
            </Menu.Item>
            <Menu.Item key={`/products?shopId=${shopId}&status=${ProductShopStatusEnum.REMOVED}`}>
              <Link to={toLink(ProductShopStatusEnum.REMOVED)} replace>
                {t('sidebar_removed')}
              </Link>
            </Menu.Item>

            <Menu.Item key={`/products?shopId=${shopId}&status=${ProductStatusEnum.IGNORED}`}>
              <Link to={toLink(ProductStatusEnum.IGNORED)} replace>
                {t('sidebar_products_ignored')}
              </Link>
            </Menu.Item>
            <Menu.Item key={`/products?shopId=${shopId}&status=${ProductShopStatusEnum.ARCHIVED}`}>
              <Link to={toLink(ProductShopStatusEnum.ARCHIVED)} replace>
                {t('sidebar_archived')}
              </Link>
            </Menu.Item>
          </Menu.SubMenu>
        )}

        {checkAccess(PermissionEnum.READ_PRODUCT_DATA) && (
          <Menu.SubMenu
            key="products_price"
            icon={<ProductsIcon />}
            title={t('sidebar_products_price')}
          >
            <Menu.Item key="/pricing" className="menu__pricing">
              <Link to={{ pathname: '/price', search: `shopId=${shopId}` }} replace>
                {t('pricing_title')}
              </Link>
            </Menu.Item>
            <Menu.Item key="/price/export">
              <Link to={{ pathname: '/price/export', search: `shopId=${shopId}` }}>
                Local Products Price
              </Link>
            </Menu.Item>
            <Menu.Item key="/price-logs">
              <Link to={{ pathname: '/price/logs', search: `shopId=${shopId}` }}>
                {t('price_logs')}
              </Link>
            </Menu.Item>
            <Menu.Item key="/price-profiles">
              <Link to={{ pathname: '/price/profiles', search: `shopId=${shopId}` }}>
                Price profiles
              </Link>
            </Menu.Item>
          </Menu.SubMenu>
        )}

        {checkAccess(PermissionEnum.READ_CATEGORY) && (
          <Menu.SubMenu key="/categories" icon={<CategoryIcon />} title={t('sidebar_categories')}>
            <Menu.Item key="/categories/update">
              <Link to={{ pathname: '/categories/update', search: `shopId=${shopId}` }}>
                {t('sidebar_product_categories')}
              </Link>
            </Menu.Item>
            <Menu.Item key="/categories/show">
              <Link to={{ pathname: '/categories/show', search: `shopId=${shopId}` }}>
                {t('sidebar_show_categories')}
              </Link>
            </Menu.Item>
          </Menu.SubMenu>
        )}

        {/* {checkAccess(PermissionEnum.READ_ATTRIBUTE) && (
          <Menu.SubMenu key="7" icon={<AttributesIcon />} title={t('sidebar_attributes')}>
            {checkAccess(PermissionEnum.READ_ATTRIBUTE) && (
              <Menu.Item key="sub-11-1">
                <Link to="/attributes">{t('sidebar_attributes')}</Link>
              </Menu.Item>
            )}

            {checkAccess(PermissionEnum.READ_ATTRIBUTE_GROUP) && (
              <Menu.Item key="sub-11-2">
                <Link to="/attribute-groups">Attrbutes group</Link>
              </Menu.Item>
            )}
            {checkAccess(PermissionEnum.READ_ATTRIBUTE_GROUP) && (
              <Menu.Item key="sub-11-3">
                <Link to="/filter-attributes">Filter attributes</Link>
              </Menu.Item>
            )}
          </Menu.SubMenu>
        )} */}

        {checkAccess(PermissionEnum.READ_ORDER) && (
          <Menu.SubMenu key="orders" icon={<OrdersIcon />} title={t('sidebar_orders')}>
            <Menu.Item key="/orders">
              <Link to={{ pathname: '/orders', search: `shopId=${shopId}` }}>Sales Orders</Link>
            </Menu.Item>
            <Menu.Item key="/orders/reseller">
              <Link to={{ pathname: '/orders/reseller', search: `shopId=${shopId}` }}>
                Purchase Orders
              </Link>
            </Menu.Item>
            {checkAccess(PermissionEnum.READ_ORDER_SELLER) && (
              <Menu.Item key="/orders/local">
                <Link to={{ pathname: '/orders/local', search: `shopId=${shopId}` }}>
                  Purchase Orders Local
                </Link>
              </Menu.Item>
            )}
            <Menu.Item key="/orders/packages">
              <Link to={{ pathname: '/orders/packages', search: `shopId=${shopId}` }}>
                Packages
              </Link>
            </Menu.Item>
            <Menu.Item key="/orders/bulk">
              <Link to={{ pathname: '/orders/bulk', search: `shopId=${shopId}` }}>Bulk update</Link>
            </Menu.Item>
            <Menu.Item key="/orderslineitems/bulk">
              <Link to={{ pathname: '/orderslineitems/bulk', search: `shopId=${shopId}` }}>
                OLI Bulk update
              </Link>
            </Menu.Item>
          </Menu.SubMenu>
        )}

        {checkAccess(PermissionEnum.READ_PRODUCT_DATA) ? (
          <Menu.SubMenu key="local" icon={<LocalSellers />} title={t('sidebar_local_sellers')}>
            {/* <Menu.Item key="sub-10-6">
              <Link to="/local/dashboard">Dashboard</Link>
            </Menu.Item> */}
            <Menu.Item key="/local/sellers">
              <Link to={{ pathname: '/local/sellers', search: `shopId=${shopId}` }}>
                {t('sidebar_sellers')}
              </Link>
            </Menu.Item>
            {checkAccess(PermissionEnum.READ_PRODUCT_SELLER) && (
              <>
                <Menu.Item key="/local/products">
                  <Link to={{ pathname: '/local/products', search: `shopId=${shopId}` }}>
                    {t('sidebar_products')}
                  </Link>
                </Menu.Item>
                <Menu.Item key="/local/archive-products">
                  <Link to={{ pathname: '/local/archive-products', search: `shopId=${shopId}` }}>
                    Archive products
                  </Link>
                </Menu.Item>
                {/* <Menu.Item key="sub-10-11">
                  <Link to="/local/import">Import</Link>
                </Menu.Item> */}
              </>
            )}
            <Menu.Item key="/local/service">
              <Link to={{ pathname: '/local/service', search: `shopId=${shopId}` }}>
                {t('sidebar_service')}
              </Link>
            </Menu.Item>
            <Menu.Item key="/local/academy">
              <Link to={{ pathname: '/local/academy', search: `shopId=${shopId}` }}>
                {t('sidebar_academy')}
              </Link>
            </Menu.Item>
          </Menu.SubMenu>
        ) : (
          <>
            {checkAccess(PermissionEnum.READ_PRODUCT_SELLER) && (
              <>
                <Menu.Item key="/local/products" icon={<ProductsIcon />}>
                  <Link to={{ pathname: '/local/products', search: `shopId=${shopId}` }}>
                    {user?.roles.includes('Local Seller')
                      ? 'Produktet'
                      : `${t('sidebar_products')}`}
                  </Link>
                </Menu.Item>
                <Menu.Item key="/local/archive-products" icon={<ProductsIcon />}>
                  <Link to={{ pathname: '/local/archive-products', search: `shopId=${shopId}` }}>
                    Archive products
                  </Link>
                </Menu.Item>
              </>
            )}
            {checkAccess(PermissionEnum.READ_ORDER_SELLER) && (
              <Menu.Item key="/orders/local" icon={<OrdersIcon />}>
                <Link to={{ pathname: '/orders/local', search: `shopId=${shopId}` }}>
                  {user?.roles.includes('Local Seller') ? 'Porositë' : `${t('sidebar_orders')}`}
                </Link>
              </Menu.Item>
            )}

            <Menu.Item className="service__sidebar" key="/local/service" icon={<ServiceIcon />}>
              <Link to={{ pathname: '/local/service', search: `shopId=${shopId}` }}>
                {t('sidebar_service')}
              </Link>
            </Menu.Item>
            <Menu.Item className="service__sidebar" key="/local/academy" icon={<AcademyIcon />}>
              <Link to={{ pathname: '/local/academy', search: `shopId=${shopId}` }}>
                {t('sidebar_academy')}
              </Link>
            </Menu.Item>
          </>
        )}

        {checkAccess(PermissionEnum.READ_PRODUCT_DATA) && (
          <Menu.SubMenu key="settings" icon={<SettingsIcon />} title={t('sidebar_settings')}>
            {checkAccess(PermissionEnum.READ_PRODUCT_DATA) && (
              <Menu.Item key="/settings/seller-informations">
                <Link
                  to={{ pathname: '/settings/seller-informations', search: `shopId=${shopId}` }}
                >
                  {t('sidebar_seller_info')}
                </Link>
              </Menu.Item>
            )}

            {checkAccess(PermissionEnum.CREATE_COMPANY) && (
              <Menu.Item key="/settings/company">
                <Link to={{ pathname: '/settings/company', search: `shopId=${shopId}` }}>
                  {t('sidebar_company')}
                </Link>
              </Menu.Item>
            )}

            {(checkAccess(PermissionEnum.READ_USER) ||
              checkAccess(PermissionEnum.READ_PERMISSION)) && (
              <Menu.Item key="/settings/permission">
                <Link to={{ pathname: '/settings/permission', search: `shopId=${shopId}` }}>
                  Permissions
                </Link>
              </Menu.Item>
            )}

            {/* {checkAccess(PermissionEnum.READ_PRODUCT) && (
              <Menu.Item key="sub-9-4">
                <Link to="/settings/export">{t('sidebar_export')}</Link>
              </Menu.Item>
            )} */}

            {checkAccess(PermissionEnum.UPDATE_PRODUCT_DATA) && (
              <>
                {user?.roles.includes('Super Admin') && (
                  <Menu.Item key="/settings/advanced">
                    <Link to={{ pathname: '/settings/advanced', search: `shopId=${shopId}` }}>
                      Advanced Settings
                    </Link>
                  </Menu.Item>
                )}
                <Menu.Item key="/settings/import">
                  <Link to={{ pathname: '/settings/import', search: `shopId=${shopId}` }}>
                    {t('sidebar_import')}
                  </Link>
                </Menu.Item>
                <Menu.Item key="/settings/send-to-shop">
                  <Link to={{ pathname: '/settings/send-to-shop', search: `shopId=${shopId}` }}>
                    Bulk Update
                  </Link>
                </Menu.Item>
                <Menu.Item key="/settings/service">
                  <Link to={{ pathname: '/settings/service', search: `shopId=${shopId}` }}>
                    Service Page
                  </Link>
                </Menu.Item>
                <Menu.Item key="/settings/clone">
                  <Link to={{ pathname: '/settings/clone', search: `shopId=${shopId}` }}>
                    Clone products
                  </Link>
                </Menu.Item>
                <Menu.Item key="/settings/smtp-email">
                  <Link to={{ pathname: '/settings/smtp-email', search: `shopId=${shopId}` }}>
                    SMTP Email
                  </Link>
                </Menu.Item>
                {/* <Menu.Item key="sub-9-6">
                  <Link to="/settings/margin">{t('sidebar_margin')}</Link>
                </Menu.Item>
                <Menu.Item key="sub-9-7">
                  <Link to="/settings/metrics-user">{t('sidebar_metrics')}</Link>
                </Menu.Item> */}
                <Menu.Item key="/settings/exchange">
                  <Link to={{ pathname: '/settings/exchange', search: `shopId=${shopId}` }}>
                    {t('currency_exchange')}
                  </Link>
                </Menu.Item>
                <Menu.Item key="/settings/delivery-time">
                  <Link to={{ pathname: '/settings/delivery-time', search: `shopId=${shopId}` }}>
                    Delivery Time
                  </Link>
                </Menu.Item>
              </>
            )}

            {checkAccess(PermissionEnum.READ_LANGUAGE) && (
              <Menu.Item key="/settings/languages">
                <Link to={{ pathname: '/settings/languages', search: `shopId=${shopId}` }}>
                  {t('sidebar_languages')}
                </Link>
              </Menu.Item>
            )}
          </Menu.SubMenu>
        )}
      </Menu>
    </Layout.Sider>
  );
};

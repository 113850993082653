import { ReactElement, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import { ProctetedRoute, RequireAuthRoute, RequireNonAuthRoute } from './components';
import { PermissionEnum as PER } from './enums/Permission.enum';
import { useAuth } from './hooks/useAuth';

// pages
import {
  ErrorPage,
  LoginPage,
  DashboardPage,
  CompanyPage,
  ProductsPage,
  PackagesPage,
  // AttributesPage,
  // AtributeGroupPage,
  // ResetPaswwordPage,
  CloneProductsPage,
  ForgotPasswordPage,
  CategoriesPage,
  LanguagesPage,
  ProfitPage,
  ImportPage,
  MetricsUserPage,
  RegisterPage,
  ExportPage,
  PermissonsPage,
  PricingPage,
  LocalSellersPage,
  LocalProductPage,
  ApplicationFormPage,
  ConfirmResetPassword,
  LocalServicePage,
  SellerInformationsPage,
  LocalAcademyPage,
  MergedOrdersPage,
  SplitResellerOrderPage,
  SplitLocalOrderPage,
  ExchangePage,
  LocalProductArchivePage,
  LocalSellerImport,
  PriceLogsPage,
  DeliveryTimePage,
  SendToShopPage,
  SmtpEmailPage,
  BulkOrderPage,
  ProductsReviewPage,
  AdvancedPage,
  ServicePage,
  BulkOrdersLineItemsPage,
  PriceProfilesPage,
} from './pages/index';
import { Loader } from 'react-feather';
import CategoriesUpdatePage from './pages/Dashboard/Categories/CategoriesUpdatePage';

const App = (): ReactElement => {
  const { loading } = useAuth();

  if (loading) return <div>Loading...</div>;

  return (
    <Routes>
      <Route element={<RequireAuthRoute />}>
        {/* Allowed roles props should pass the permissions required, the type of array means for the
        required allowed route if all permissions pass then route is available */}

        <Route path="/" element={<DashboardPage />} />
        <Route element={<ProctetedRoute allowedRoles={[PER.READ_PRODUCT]} />}>
          <Route
            path="/products"
            element={
              <Suspense fallback={<Loader />}>
                <ProductsPage />
              </Suspense>
            }
          />
          <Route
            path="/products/review"
            element={
              <Suspense fallback={<Loader />}>
                <ProductsReviewPage />
              </Suspense>
            }
          />
        </Route>

        <Route element={<ProctetedRoute allowedRoles={[PER.READ_PRODUCT_DATA]} />}>
          <Route path="/price" element={<PricingPage />} />
          <Route path="/price/export" element={<ExportPage />} />
          <Route path="/price/logs" element={<PriceLogsPage />} />
          <Route path="/price/profiles" element={<PriceProfilesPage />} />
        </Route>

        <Route element={<ProctetedRoute allowedRoles={[PER.READ_ORDER]} />}>
          <Route path="/orders" element={<MergedOrdersPage />} />
          <Route path="/orders/reseller" element={<SplitResellerOrderPage />} />
        </Route>

        <Route element={<ProctetedRoute allowedRoles={[PER.READ_ORDER_SELLER]} />}>
          <Route path="/orders/local" element={<SplitLocalOrderPage />} />
        </Route>
        <Route element={<ProctetedRoute allowedRoles={[PER.READ_ORDER]} />}>
          <Route path="/orders/packages" element={<PackagesPage />} />
          <Route path="/orders/bulk" element={<BulkOrderPage />} />
          <Route path="/orderslineitems/bulk" element={<BulkOrdersLineItemsPage />} />
        </Route>

        <Route element={<ProctetedRoute allowedRoles={[PER.READ_SELLER, PER.READ_PRODUCT_DATA]} />}>
          <Route path="/local/sellers" element={<LocalSellersPage />} />
        </Route>

        {/* <Route element={<ProctetedRoute allowedRoles={[PER.READ_SELLER, PER.READ_PRODUCT_DATA]} />}>
          <Route path="/local/dashboard" element={<LocalDashboard />} />
        </Route> */}

        <Route element={<ProctetedRoute allowedRoles={[PER.READ_PRODUCT_SELLER]} />}>
          <Route path="/local/products" element={<LocalProductPage />} />
        </Route>
        <Route element={<ProctetedRoute allowedRoles={[PER.READ_PRODUCT_SELLER]} />}>
          <Route path="/local/archive-products" element={<LocalProductArchivePage />} />
        </Route>
        <Route element={<ProctetedRoute allowedRoles={[PER.UPDATE_PRODUCT_SELLER]} />}>
          <Route path="/local/import" element={<LocalSellerImport />} />
        </Route>
        <Route path="/local/service" element={<LocalServicePage />} />
        <Route path="/local/academy" element={<LocalAcademyPage />} />

        {/* <Route element={<ProctetedRoute allowedRoles={[PER.READ_ATTRIBUTE]} />}>
          <Route path="/attributes" element={<AttributesPage />} />
        </Route>

        <Route element={<ProctetedRoute allowedRoles={[PER.READ_ATTRIBUTE_GROUP]} />}>
          <Route path="/attribute-groups" element={<AtributeGroupPage />} />
        </Route> */}

        <Route element={<ProctetedRoute allowedRoles={[PER.READ_CATEGORY]} />}>
          <Route path="/categories/show" element={<CategoriesPage />} />
          <Route path="/categories/update" element={<CategoriesUpdatePage />} />
        </Route>

        <Route element={<ProctetedRoute allowedRoles={[PER.READ_COMPANY]} />}>
          <Route path="/settings/company" element={<CompanyPage />} />
        </Route>

        <Route element={<ProctetedRoute allowedRoles={[PER.READ_PERMISSION, PER.READ_USER]} />}>
          <Route path="/settings/permission" element={<PermissonsPage />} />
        </Route>

        <Route element={<ProctetedRoute allowedRoles={[PER.UPDATE_PRODUCT_DATA]} />}>
          <Route path="/settings/advanced" element={<AdvancedPage />} />
          <Route path="/settings/import" element={<ImportPage />} />
          <Route path="/settings/margin" element={<ProfitPage />} />
          <Route path="/settings/send-to-shop" element={<SendToShopPage />} />
          <Route path="/settings/service" element={<ServicePage />} />
          <Route path="/settings/clone" element={<CloneProductsPage />} />
          <Route path="/settings/smtp-email" element={<SmtpEmailPage />} />
          <Route path="/settings/metrics-user" element={<MetricsUserPage />} />
          <Route path="/settings/languages" element={<LanguagesPage />} />
          <Route path="/settings/exchange" element={<ExchangePage />} />
          <Route path="/settings/delivery-time" element={<DeliveryTimePage />} />
        </Route>

        <Route element={<ProctetedRoute allowedRoles={[PER.READ_PRODUCT_DATA]} />}>
          <Route path="/settings/seller-informations" element={<SellerInformationsPage />} />
        </Route>
      </Route>

      <Route element={<RequireNonAuthRoute />}>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/application" element={<ApplicationFormPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/reset-password/:token" element={<ConfirmResetPassword />} />

        {/* <Route path="/reset-password" element={<ResetPaswwordPage />} /> */}
      </Route>

      <Route path="/404" element={<ErrorPage />} />
      <Route path="/*" element={<ErrorPage />} />
    </Routes>
  );
};

export default App;
